
// export const myAction = ( state ) => {

// }


export const questionRight = ( state ,  data  ) => {
   console.log('mutation ' , data )
    state.question.push( data )
    return { ok : true }
}

export const clean = ( state ) => {
    state.question = []
    state.count = 0
    return { ok : true }
}

export const result = ( state , result ) => {
    state.result = result
    return { ok : true }
}


// export const loginUser = ( state, { user, idToken, refreshToken }) => {
//     //debugger
//     if ( idToken ) {
//         localStorage.setItem( 'idToken', idToken )
//         state.idToken = idToken
//     }

//     if ( refreshToken ) {
//         localStorage.setItem( 'refreshToken', refreshToken )
//         state.refreshToken = refreshToken
//     }

//     state.user   = user
//     state.status = 'authenticated'
// }



