import { createStore } from 'vuex'

import auth   from '../modules/auth/store'
import trivia from '../modules/trivia-futbol/store'


export default createStore({
  // state: {
  // },
  // getters: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    auth,
    trivia,
  }
})
