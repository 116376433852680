


import axios from 'axios'


const authApi = axios.create({
    baseURL: 'https://identitytoolkit.googleapis.com/v1/accounts',
    params: {
        key: 'AIzaSyBhCquFEBH1rgR5IJFo1v1qGiZzHDe2PVI'
    }
})

export const localLogin = ( user , password ) => {
    const email = 'game@salomonhub.com'
    const pss = 'smhb@22953'
    const idToken  = 'A%D*G-JaNdRgUkXp2s5v8y/B?E(H+MbP'
    const refreshToken = 'z$C&F)J@NcRfTjWnZr4u7x!A%D*G-KaP'
    console.log(user , email , pss , password , ' keys  ')
    return new Promise( (resolve) => {
        //setTimeout( () => {} , 1000 );
        if( user == email && pss == password ){
            resolve( {
                data : {
                    status : true ,
                    idToken ,
                    refreshToken
                }
            })
        }
        else{
            resolve( {
                data : {
                    status : false
                }
            })
        }

    } );
};

// console.log( process.env.NODE_ENV ) // TEST durante testing, 

export default { 
    authApi 
}