import isAuthenticatedGuard from '@/modules/auth/router/auth-guard'


const trivia = {
        path: '/trivia',
        name: 'trivia',
        beforeEnter: [ isAuthenticatedGuard ],

        component: () => import(/* webpackChunkName: "homeTrivia" */ '@/modules/trivia-futbol/layouts/triviaLayout.vue'),
        children: [

            { 
                path: '', 
                name: 'trivia-home',
                component: () => import(/* webpackChunkName: "Home" */ '@/modules/trivia-futbol/pages/HomePage'),
                meta: { transition: 'slide-left' },
            },
            { 
                path: 'question', 
                name: 'trivia-question',
                component: () => import(/* webpackChunkName: "Question" */ '@/modules/trivia-futbol/pages/QuestionPage'),
            },
            { 
                path: 'endgame', 
                name: 'trivia-endgame',
                component: () => import(/* webpackChunkName: "EndGame" */ '@/modules/trivia-futbol/pages/EndGamePage'),
            },

        ]
    }

export default trivia