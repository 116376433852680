import trivia from '@/modules/trivia-futbol/router'
import { createRouter , createWebHistory } from 'vue-router'
// createWebHistory
import authRouter from '../modules/auth/router'
//import HomeView from '../views/HomeView.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/',
    // path: '/auth',
    ...authRouter,
  },
  { 
    ...trivia 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: '/'
})

  

export default router
