import  {authApi ,localLogin}  from '@/api/authApi'

// export const myAction = async ({ commit }) => {


// }


export const createUser = async ({ commit }, user ) => {

    // name,
    const {  email, password } = user

    try {
        
        //const { data } = await authApi.post(':signUp', { email, password, returnSecureToken: true })
        const { data } = await localLogin( email, password )
        const { idToken, refreshToken , status } = data

        //await authApi.post(':update', { displayName: name, idToken, refreshToken })
        if( status ){
            delete user.password
            commit('loginUser', { user, idToken, refreshToken })
    
            return { ok: true }
        }
        else{
            return { 
                ok : false , 
                message: 'Error logueo'
            }
        }

    } catch (error) {
        return { ok: false, message: error.response.data.error.message }
    }

}

export const signInUser = async ({ commit }, user ) => {

     // name,
     const {  email, password } = user

     try {
         console.log( email , password , ' credenciales ')
         const { data } = await localLogin( email, password )
         console.log( data , 'response data ')
         
        //debugger
         const { status } = data
         if( status ){
             const { idToken, refreshToken } = data
             delete user.password
             commit('loginUser', { user, idToken, refreshToken })
     
             return { ok: true }
         }
         else{
             return { 
                 ok : false , 
                 message: 'Error logueo'
             }
         }
 
     } catch (error) {
        debugger
         return { ok: false, message: 'error' }
     }

}

export const signInUser2 = async ({ commit }, user ) => {

    const { email, password } = user

    try {
        
        const { data } = await authApi.post(':signInWithPassword', { email, password, returnSecureToken: true })
        const { displayName, idToken, refreshToken } = data
        
        user.name = displayName

        commit('loginUser', { user, idToken, refreshToken })

        return { ok: true }

    } catch (error) {
        return { ok: false, message: error.response.data.error.message }
    }

}


export const checkAuthentication = async ({ commit }) => {

    const idToken      = localStorage.getItem('idToken')
    const refreshToken = localStorage.getItem('refreshToken')

    if( !idToken ) {
        commit('logout')
        return { ok: false, message: 'No hay token' }
    }

    try {
        
        //const { data } = await authApi.post(':lookup', { idToken })
        //const { displayName, email } = data.users[0]
        // console.log(data)

        const user = {
            name: 'Game Salomon',
            email :'game@salomonhub.com'
        }

        commit('loginUser', { user, idToken, refreshToken })

        return { ok: true }

    } catch (error) {
        commit('logout')
        return { ok: false, message: error.response.data.error.message }
    }

}