
// export const myGetter = ( state ) => {
//  return state
// }

export const allQuestion = ( state ) => {
    return state.question
}

export const count = ( state ) => {
    return state.result
}





